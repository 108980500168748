import { cpf } from 'cpf-cnpj-validator';

export function checkUsername(username) {
  var words = ["gmail", "hotmail", "yahoo", "msn", "live", "com", "br"];
  var check = true;

  words.map(word => {
    if (username.match(word)) {
      check = false;
    }
  });

  return check;
}

export function checkCpf(taxid) {
  var check = true;
  if (!cpf.isValid(taxid)) {
    check = false;
  }

  return check;
}

export function checkAge(birthday) {
  if (!birthday) {
    return true
  }

  var year = birthday.split('/')[2];
  var today = new Date().getFullYear();
  var age = today - year;

  var check = false;

  if (year && year.length == 4 && age >= 18) {
    check = true;
  }

  return check;
}